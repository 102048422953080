import React, { Component } from 'react'
import {
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Row,
} from 'reactstrap'
import * as PropTypes from 'prop-types'
import NdaCheckBox from './NdaCheckBox'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class StepFive extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stepFiveName: '',
      stepFiveEmail: '',
      stepFivePhone: '',
      stepFiveNda: false,
    }
  }

  componentDidUpdate = () => {
    this.props.onUpdate(this.state)
  }

  render() {
    return (
      <div className="bg-lightest py-4 ">
        <Container>
          <Row className="py-4 mb-4">
            <Col xs={12} md={1} lg={2}>
              <h1 className="step-label">5</h1>
            </Col>
            <Col xs={12} md={10} lg={8}>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  this.props.onSubmit()
                }}
              >
                <Row form>
                  <Col xs={12} className="mb-2">
                    <h2 className="m-0 py-3">Mijn contactgegevens</h2>
                  </Col>
                  <Col xs={12}>
                    <h4>Naam</h4>
                    <FormGroup>
                      <Input
                        required
                        type="name"
                        name="name"
                        onChange={e =>
                          this.setState({ stepFiveName: e.target.value })
                        }
                        className="form-control contact-details-input"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} className="pr-md-3">
                    <h4>Email</h4>
                    <FormGroup>
                      <Input
                        required
                        type="email"
                        name="email"
                        onChange={e =>
                          this.setState({ stepFiveEmail: e.target.value })
                        }
                        className="form-control contact-details-input"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} className="pl-md-3">
                    <h4>Telefoonnummer</h4>
                    <FormGroup>
                      <Input
                        placeholder="+1..."
                        className="form-control contact-details-input"
                        onChange={e =>
                          this.setState({ stepFivePhone: e.target.value })
                        }
                      />
                      <FormText>Gelieve landcode op te nemen</FormText>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={10} className="md-checkbox">
                    <NdaCheckBox
                      onCheckChange={isChecked =>
                        this.setState({ stepFiveNda: isChecked })
                      }
                      label="Ik wil graag een NDA-concept ontvangen"
                    />
                  </Col>
                  <Col xs={12} className="get-estimate-button-wrapper">
                    <button
                      disabled={this.props.estimationButtonState === ButtonState.STATE_SENDING ? true : false}
                      type="submit"
                      className="btn btn-lg btn-primary get-estimate-button"
                      name="get-estimate-button"
                      id={this.props.estimationButtonState === ButtonState.STATE_SENT ? 'estimation-sent-btn' : 'get-estimate-btn'}
                    >
                      {this.props.estimationButtonState ===
                        ButtonState.STATE_SENDING && (
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            style={{ marginRight: '0.5rem' }}
                          />
                        )}
                      {this.props.estimationButtonState}
                    </button>
                  </Col>
                  <Col xs={12}>
                    <p className="estimation-recaptcha-disclaimer">
                      Deze website is beveiligd door reCAPTCHA.
                      <br />
                      <a href="https://policies.google.com/privacy"
                                Privacybeleid van Google
                        rel="noopener noreferrer"
                        target="_blank">
                      
                        Privacybeleid
                      </a>{' '}
                      en de{' '}
                      <a
                        href="https://policies.google.com/terms"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Servicevoorwaarden
                      </a>{' '}
                      &nbsp;van Google zijn dus van toespassing
                    </p>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col xs={12} md={1} lg={2} />
          </Row>
        </Container>
      </div>
    )
  }
}

export const ButtonState = {
  STATE_READY: 'Krijg een schatting',
  STATE_SENDING: 'Verzenden...',
  STATE_SENT: 'Succes! Kijk in je inbox',
  STATE_ERROR: 'Probeer opnieuw',
}
StepFive.propTypes = {
  onUpdate: PropTypes.func,
  estimationButtonState: PropTypes.string,
  onSubmit: PropTypes.func,
}

export default StepFive
