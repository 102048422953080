import React from 'react'
import logo from '../../assets/images/zaven_logo_googleapps.png'
import { Col, Container, Row } from 'reactstrap'

const CEO = () => (
  <div className="bg-lightest ceo-container">
    <Container>
      <Row>
        <Col xs={0} md={1} lg={2} />
        <Col xs={3} lg={2}>
          <img src={logo} className="ceo-picture" alt={'zaven-logo'} />
        </Col>
        <Col sm={9} xs={12} md={7} lg={6} className="ceo-message-container">
          <p className="ceo-message">
            Hallo!
            <br />
            Wij zijn het team van Zaven en we doen u graag een voorstel. 
            Gelieve deze paar vragen te beantwoorden om ons een beter idee te geven van uw project.
          </p>
        </Col>
        <Col xs={0} md={1} lg={2} />
      </Row>
    </Container>
  </div>
)

export default CEO
